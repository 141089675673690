body {
  margin: 0;
  font-family: "Barlow", sans-serif !important;
  /* font-style: normal; */
}
.status-cell {
  text-align: center;
}
/* Assuming you have a class for the column */
.contact-column {
  width: 100px; /* Adjust width as needed */
  white-space: nowrap; /* Prevent text wrapping */
}
#app_setting_timezone{
  border-color: #403e3e !important;
}
.contact-column-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust spacing as needed */
}

.dt-processing  {
  display: none;
}
div.dt-processing>div:last-child{
  display: none;
}
#floatingRectangle {
  z-index: 1;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #000;
  color: white;
  padding: 0;
}
.li-input{
  display: none;
  box-shadow: none !important;
  color:white !important;
}
.Privacy{
  color:black !important
}
.cht-icon{
  margin: 0 auto;
}
li:hover input[class="li-input"] {
  display: inline-block;
}
.ib-coutner-alert{
  font-size: 13px;
  border: none !important;
  height: 30px;
}
.fa-trash-icon {
  cursor: pointer;
  margin: 0 auto;
  /* display: none; */
  margin-right: 12px !important;
  color: red;
}
.inbox-btn{
  background: transparent;
    color: #b6bee3 !important;
    border: none;
}
/* li:hover .fa-trash-icon {
  display: inline;
}

li:hover .cht-icon {
  display: none;
} */

input[type="checkbox"]:checked {
  display: inline-block;

  accent-color: #f9b840;
  
}
input[class="li-input"]:checked {
  display: inline-block;
  color:white !Important;
}
.red{
  color:rgba(242, 23, 23, 0.907)  !important
}
#box{
  display: none;
}
#chat-sel{
padding: 0 6px;
border: 1px solid #ffa000f2;
cursor: pointer;
border-radius: 4px;
background: #ffa000f2;
  color: #fff;
  font-weight: 500;
  width: 19px;
}
#box:checked + #chat-sel {
  background: #ffa000f2;
  color: #fff;
  font-weight: 500;
}
.sel-num{
  margin-left: 10px;
  
    color: black !important;
    font-weight: 400;
}
.chat-selec{
  background: #fff;
    padding: 12px;
    border-radius: 8px;
    width: '12px';
}
.alert-info{
  font-size: 15px;
    color: #fff;
    padding: 12px;
    background: #f9b840 !important;
    border-color: #f9b840;
}
.csv_detail{
  color: #f9b840 !important;
    margin-right: 9px;
    font-weight: 500;
}

.importOption:focus {
  outline: none  !important;
}
.btn_primary:hover{
  box-shadow: 0 0 10px #0000001a;
  border-color: #f9b840 !important;
    background: #f9b840 !important;
    color: #fff !important;
    
}
.login{
  
    background-color: white !important; /* Apply background color only to specific page */
  
}
.next_btn{
  border-radius: 50px;
    background: #f9b840;
    margin-left: 21px;
}
.select_col{
  background-color: #f9b840 !important;
    color: #fff ! IMPORTANT;
}

.select_col::placeholder {
  color: #fff !important; /* Change placeholder color as needed */
}
.col-1 > img {
  display: none; /* Ensure the image is initially displayed */
}

.LST-TAG {
  transition: transform 0.9s ease-in-out;
  transform: translateX(-100%);
}

.LST-TAG.showh {
  transform: translateX(0);
}
.pre_btn{
  border-radius: 50px;
    background: #a5a5a578;
}
.flip{

    transform: scaleX(-1);
  
}
.alert-danger{
  background-color: #5c161642  !important;
    border-color: #cf28288c !important;
}
.add-icon-crm:hover{

    background-color: #f9b840 !important;
    
}

.first {
  position:absolute;
  /* width:40px; */
  /* left:0; */
  top:auto;
  background: red;
}
/* Example CSS */
/* .crm_tabble {
  position: relative;
}

.crm_tabble tfoot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
} */

.second {
  position:absolute;
  left:42px; 
  /* top:auto; */
  background: green;
}
.wraps {
 
  overflow-x:scroll;  
  
}
::-webkit-scrollbar-corner {
  display: none;
}
.add-icon-crm{
  margin-left: 5px;
    margin-right: 5px;
    background-color: #f9b840;
    width: 33px;
    height: 34px;
    border-radius: 49px;
}
td {
  /* white-space: pre-wrap; */
  word-wrap: break-word;
}
#temp-name{
  background-color: rgba(88, 88, 88, 0.73) !important;
}
.count-selection{
  color: #ffcc6e !important;
    margin-right: 9px;
    font-weight: 500;
}
#message-area{
  height: 103px;
    background-color: #585858ba !important;
}
.disabl_field{
  margin: 24px 80px 14px 14px;
}
#dropdown-basic{
  border-radius: 50px;
  padding: 6px 26px 8px 21px;
  background-color: #494949;
  border-color: #49494949;
  margin-left: 12px;
  margin-right: 12px;
}
.dropdown-toggle::after{
  /* background: #f9b840 !important; */
  margin-top: 10px;
  /* margin-top: 6px; */
    float: right;
    margin-left: 12px;
}
.add_btn{
  min-width: 143px;
  border-radius: 50px;
    font-size: 15px;
    line-height: 18px;
    box-shadow: 0 0 10px #0000001a;
    background: #f9b840;
    /* box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.48); */
    color: #fff !important;

}
.lst_tag {
  font-style: normal;
  font-weight: 400;
  font-family: "Barlow", sans-serif !important;
  font-size: 16px;
  /* line-height: 20px; */
  /* color: #454545; */
  /* height: 33px; */
  padding: 6px 23px;
  text-align: 'left';
  border-radius: 50px;
  width:100% ;
  background: #494949 !important;
  border-color: #494949 !important;
  outline: none  !important;
    color: #fff !important;
}
 .importOption {
  font-style: normal;
  font-weight: 400;
  font-family: "Barlow", sans-serif !important;
  font-size: 16px;
  /* line-height: 20px; */
  /* color: #454545; */
  /* height: 33px; */
  padding: 6px 23px;
  border-radius: 50px;
  background: #494949 !important;
  border-color: #494949 !important;
  outline: none  !important;
    color: #fff !important;
}
/* Make scrollbar background transparent */
::-webkit-scrollbar {
  background-color: transparent;
}
.w-60{
  width: 60% !important;
}
#msg-inputs-customs {
  background: none !important;
  padding: 0px !important;
  padding: 23px 0px 13px 1px !important;
}
.msg-inputs-custom {
  background: none !important;
  padding: 0px !important;
  margin-top: '12px' !important;

}
.other-message{
  background: #f9b840;
  font-size: 14px;
  margin-right: 13px;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  shape-outside: padding-box;
  word-break: break-word;
  padding: 5px;
  border-radius: 9px;

}
.w-40{
  width: 40% !important;
}
.crm-chats-li{
  width: 50%;
  background: transparent;
  padding: 9px;
  border: 1px solid #80808085;
  cursor: pointer;

}
#textarea-messageing {
  font-size: 16px;
  padding-top: 10px !important;
  resize: none; 
  overflow: hidden; 
  min-height: 36px; 
  line-height: 18px; 
}
.crm-active{
  border-top: none !important;
    border-right: none  !important;
    border-left: none  !important;
    background-color: #dfaa33  !important;
    color: white !important;
}
.modal-fullscreenss {
  max-width: 100%;
  width: 80%;
  height: 100%;
}
.modal-fullscreens {
  max-width: 100%;
  width: 80%;
  height: 100%;
}
/* Set scrollbar track color to transparent for Firefox */
* {
  scrollbar-color: black;
}
.fade-enter {
  opacity: 0;
}
/* reply */
.def-countl{
 
    color: #ffd400 !important;
    font-weight: 500;
}

.custom-countl{
  color: #ff2f00 !important;
    font-weight: 500;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

/* Set scrollbar thumb (the draggable bar) */
::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.647); /* You can adjust the transparency here */
  border-radius: 10px; /* Rounded corners for aesthetics */
}

/* Set scrollbar height */
::-webkit-scrollbar {
  height: 5px  !important; /* Adjust the height as per your preference */
  width: 6px  !important; /* Adjust the height as per your preference */
}

.st-icon-chat{
  /* text-align-last: right; */
  float:right;
    font-size: 21px;
}
.mappedInput{
  max-width: 80%;
    font-size: 16px !important;
    color: #fff !important;
}
.active .nav-link{
  background: #f9b840;
    /* box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.48); */
    color: #fff !important;
    border-radius: 8px;
    text-decoration: none;
}
/* .nav-pills{
  
  color:rgb(207,211,236) ;
} */

.nav-link{
  color:#cfd3ecc9  !important ;
}
.nav-link::hover{
  color:yellow  !important ;
  text-decoration: none;
}

.fileupload{
  background:#f9b840  !important ;
}
label{
  color: #fff !important;
  font-family: "Barlow", sans-serif !important;
  font-weight: 400;
}
p{
  color: #fff !important;
}
span{
  color: #fff!important;
}
input:focus {
  /* border:  1px solid #f9b840  !important; */
  border:  1px solid #3e3e3ee6 !important; /* Change to your desired color */
  box-shadow: none  !important; /* Remove default focus outline */
  color:#fff !important;
}
.app-setting-menu .acc_manager a {
  text-decoration: none;}
.app-setting-menu .acc_manager .title {
  color:#fff !important;
  margin-bottom: 11px;
  display: block;
  line-height: 19px;
}
.chat-button {
  margin-top: 8px;
  background-color: #f9b840;
  border-radius: 50px;
  color: #fff;
  width: 118px;
  height: 26px;
  font-size: 12px;
  border: none;
}
.manager-profile {
  width: 48px;
  height: 48px;
  margin: 0 auto 12px;
  border-radius: 100%;
  overflow: hidden;
}
a{
  text-decoration: none  !important;
}
.setting_mnu ul li {
  margin-bottom: 20px;
}
.setting_mnu{
  padding: 0px 7px 31px 10px;
}
.save_web_btn{
  font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    padding: 6px 20px;
    height: 34px;
    width: 140px;
    border-radius: 50px;
    background: #f9b840;
    border-color: #f9b840;
    box-shadow: 0 0 10px #0000001a;
}
.custom-backdrop{
  background-color: #494949;  /* Red with 50% opacity */
}
.modal-content{
  background-color: #313131;
  color: #fff;
  border-radius: 7px;
}
.modal-header  {
border: none  !important;
}
.modal-footer {
  border: none  !important;
  text-align: center;
  }
.setting_mnu li.active a {
  background: linear-gradient(0deg, #f9b840, #f9b840), #24356C;
  color: #fff;
}
.setting_mnu  a:hover {
  background: linear-gradient(0deg, #f9b840, #f9b840), #24356C;
  color: #fff;
}
 .tab {
  margin-top: 17px;
  margin-bottom: 25px;
  border-bottom: 2px solid #C3C3C3;
  display: flex;
  margin-left: 20px;
}
.tab  .link {
  color: #fff  !important;
  /* font-weight: 600; */
  cursor: pointer;
}

.tab-item:hover .strip {
  opacity: 1;
  transform: scaleX(1);
}
.strip:before{
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  border-style: solid;
  border-width: 0 5px 4px 5px;
  border-color: transparent transparent #f9b840 transparent;
}


.btn-primary:focus{
  background-color: #f9b840 !important;
  border-color: #f9b840  !important;
  outline: none !important ;
  
}
.btn-primary:hover{
  background-color: #f9b840;
  border-color: #f9b840  !important;
  outline: none !important ;
  
}
.st_list{
  color: white;
    font-size: 18px;
    /* margin-left: 6px; */
    padding: 5px;
}
.strip{
  height: 5px;
    background-color: #f9b840;
    position: relative;
    bottom: -2px;
}

.userEditForm  {
  position: relative;
  background: #494949 !important;
  border-radius: 10px;
  box-shadow: 0 0 5px #0000000d;
  padding: 20px 15px;
  width: 100%;
  /* height: 100%; */
}
.save_btn {
  color:#fff;
  font-size: 16px;
  border-radius: 27px;
  background: #f9b840;
    border-color: #f9b840;
    box-shadow: 0 0 10px #0000001a;
}
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-button {
  font-family: "Barlow", sans-serif !important;
  padding: 7px 32px;
  background-color: #494949;
  border-radius: 27px;
  box-shadow: 0 0 4px #0000004d !important;
    border-color: #4a4a4a !important;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ball-icon{
  background-color: #f9b840 !important;
  color: #f9b840 !important;
    border-radius: 59px;
    font-size: 9px;
    margin-right: 9px;
}
#number-input{
  color: black;
  outline:none !important;
  display: block !important;
    width: 100%;
    /* padding: 0px !important; */
    font-size: 0.9375rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: black !important;
    background-color: #fff !important;
    /* background-color: #2f3349 !important; */
    background-clip: padding-box !important;
    border: 1px solid #4349681f !important;
    appearance: none !important;
    border-radius: 0.375rem !important;
    /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important; */

}
.phone-input-dropdown {
  padding-left: 10px;
  color: black !important; /* Change font color to black */
}
.upload-button:hover {
  background-color: #494949;
}
.country-name{
  color: black  !important;
}

.dial-code{
  color: black  !important;
}
.file-input-icon{
  font-size: 18px;
    color: #747272  !important;
    cursor: pointer;
    margin-right: 13px;
    margin-top: 10px;
}
.upload-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.save_btn:hover{
  color:#fff;
  font-size: 16px;
  border-radius: 27px;
  background: #f9b840;
    border-color: #f9b840;
    box-shadow: 0 0 10px #0000001a;
}


.tab .tab-item .link {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #454545;
  margin-bottom: -3px;
  position: relative;
  display: block;
}
.tab .tab-item {
  max-width: 200px;
  width: 100%;
  text-align: center;
}
.setting_mnu li a {
  display: block;
  color:#fff  !important;
  background-color: #494949 !important;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 0;
  font-size: 15px;
  width: 74%;}
.acc-manager{
  font-size: 14px;
  text-decoration: none;
  color:#fff;
  background: #494949;
  text-align: center;
    margin-top: 25px;
    display: inline-block;
    width: 75%;
    padding: 11px 0 13px;
}

h5{
  color:#cfd3ecc9  !important;
}
img{
  max-width: 100%;
}
.col-form-label{
  color: #cfd3ec ;
  font-size: 15px;
  font-weight: 500;
}
.cancel_btn{
  text-transform: uppercase;
  background-color: #494949;
    border-color: #494949;
    margin-right: 30px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    padding: 6px 20px;
    height: 34px;
    width: 140px;
    border-radius: 50px;
}
.btn-primary{
  text-transform: uppercase;
  border-radius: 50px;
    background: #f9b840;
    outline: none !important ;
    border-color: #f9b840;
    columns: #fff;
    box-shadow: 0 0 10px #0000001a;
}
.form-select {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: rgba(128, 128, 128, 0.656) !important;
  
}
.custom-google-login {
  background-color: #4285f4;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-google-login:hover {
  background-color: #357ae8;
}

.custom-google-login-text {
  margin-left: 10px;
}

.select2-results {
  background-color: #494949;
}
.custom-dorpdown-toglle:focus{
  border-radius: 50px;
    outline: none !important;
    background-color: #494949;
    color: white;
    border-color: #494949;
    font-family: sans-serif;
}
.custom-dorpdown-toglle{
  border-radius: 50px;
    outline: none !important;
    background-color: #494949;
    color: white;
    border-color: #494949;
    font-family: sans-serif;
}
input::placeholder {
  color: #b6bee3 !important; /* Change placeholder color as needed */
}
.sidebar a.active > .nav-text  {
  color: white  !important;

}
#aud-tabl-th{
  font-size: 9px !important;
}
.crm_tabble {
  table-layout: fixed; /* Force table layout to fixed */
  width: auto  !important; /* Ensure table uses full width of container */
}
.table > :not(caption) > * > * {
  padding: 0.55rem 1.25rem;
  border-bottom-width: 1px;
  /* border-collapse: collapse;  */
  border-color: inherit  !important;
  vertical-align: middle;
  border-color: #434968;
}
thead, tbody, tfoot, tr, td, th {
    border-color: inherit  !important;
    border-style: solid !important;
    /* border-width: 0 !important; */
}
.table{
  border-color: #434968;
}
tbody:last-child {
  border-color: #434968;/* Remove bottom border from last row */
}
th{
  color:#cfd3ec !important;
  text-transform: uppercase;
    font-size: 0.8125rem;
    letter-spacing: 1px;
    padding-top: 0.88rem;
    padding-bottom: 0.88rem;
}
.btn-danger{
  color: #fff;
    background-color: #ea5455;
    border-color: #ea5455;
    width: 86px;
    font-weight: 500;
    font-size: 15px;
}
td{
  color:#b6bee3d6 !important;
}
.userPasswordChange {
  position: relative;
    background: #494949;
    border-radius: 10px;
    box-shadow: 0 0 5px #0000000d;
    padding: 20px 15px;
    width: 100%;
    height: 35%;
    margin-top: 10px;
}
.custom-close-button {
  background-color: red; /* Change the background color of the close button */
  color: white; /* Change the text color of the close button */
}
.navbar{
   
    margin-left: 108px;
    margin-right: 32px;
    position: relative;
    z-index: 0;
    background-color: #3a3f39 !important;
    color: #b6bee3;
    box-shadow: 0x 0px;
    box-shadow: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
    background-color: #3a3f39 !important;
    border-radius: 10px;
}
.accept-call{
  background-color: #03ef03c2;
    /* font-size: 19px; */
    padding: 15px;
    border-radius: 100%;
}
.call-card{
  width: 350px;
  border-radius: 9px;
  color: white;
  border: 1px solid #999898;
}
.floating-button-container {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 140px; /* Adjust as needed */
  z-index: 9999; /* High z-index to ensure it appears in front */
}
.phone-right{
  font-size: 32px;
}
.digit,
.dig {
  /* float: left; */
  padding: 10px 30px;
  width: 30px;
  background: none !important;
  font-size: 2rem;
  cursor: pointer;
}

.sub {
  font-size: 0.8rem;
  color: rgba(233, 0, 0, 0.691);
}
.row-dial {
  margin: 0 auto;
  width: 280px;
  clear: both;
  text-align: center;
  font-family: 'Exo';
}
.lat-call-icon.red {
  color: red;
}

.lat-call-icon.green {
  color: #04e604;
}
.dnc-table {
  width: 100% !important;
  table-layout: fixed;
}
.progress-container {
  flex-grow: 1; /* Allow the progress container to grow and center the progress bar */
  position: relative; /* Required for positioning the label inside the progress bar */
}

/* Overlay container style */
.overlay-container {
  position: absolute; /* Position the container absolutely */
  top: 0; /* Align it to the top */
  left: 50%; /* Align it to the horizontal center */
  transform: translateX(-50%); /* Center align the container */
  z-index: 9999; /* High z-index for overlay effect */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 10px; /* Padding around the content */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for visual enhancement */
  border-radius: 8px; /* Optional rounded corners */
}

/* Style for the button */
.logout-button {
  font-size: 16px; /* Font size for better visibility */
  padding: 8px 16px; /* Padding inside the button */
  cursor: pointer; /* Change cursor on hover */
  background-color: #f44336; /* Button background color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  z-index: 9999; /* Ensure the button is on top */
}


.progress {
  background-color: #6c6c6c27; /* Background color of the progress bar */
  height: 10px; /* Height of the progress bar */
  width: 100%; /* Full width */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Ensure the progress bar fits within the container */
  position: relative; /* Required for positioning the label inside */
}

.progress-bar {
   /* Full width to indicate 100% completion */
  height: 102%;
  background-color: #f9b840 !important; /* Color of the progress */
  position: relative; /* Required for positioning the label inside */
}
.camp-card{
  text-align-last: left;
}
.progress-label {
  z-index: 999;
  position: absolute; /* Absolute positioning to center the label inside the progress bar */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for the label's own width and height */
  color: #fff; /* Text color */
  font-size: 12px; /* Adjust font size as needed */
}
.dnc-table .dt-column-order{
  display:none  !important
}
.dnc-table > .dt-end > .dt-search{
  display:none  !important
}
.dt-search{
  display:none  !important
}
.crm_table th, .crm_table td {
  padding: 10px;
  text-align: left;
}
.checkbox-column {
  text-align: left !important;
  width: 10%;
}
.action-column {
  text-align: right !important;
  width: 10%;
}
.number-column {
  text-align: left;
  width: 80%;
}
.container-dial {
  /* background-color: white;
  width: 320px; */
  padding: 20px;
  /* margin: 30px auto; */
  /* height: 420px; */
  text-align: center;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
}
.number-call-input{
  border-radius: 5px !important;
    outline: none !important;
    padding: 4px;
    width: 82%;
    margin: 0 auto;
    height: 30px;
}
/* #output {
  font-family: "Exo";
  font-size: 2rem;
  height: 60px;
  font-weight: bold;
  color: #1976d2;
} */

#call {
  display: inline-block;
  background-color: #66bb6a;
  padding: 4px 30px;
  margin: 10px;
  color: white;
  border-radius: 4px;
  float: left;
  cursor: pointer;
}

.botrow {
  margin: 0 auto;
  width: 280px;
  clear: both;
  text-align: center;
  font-family: 'Exo';
}

.digit:active,
.dig:active {
  background-color: #e6e6e6;
}

#call:hover {
  background-color: #81c784;
}

.dig {
  float: left;
  padding: 10px 20px;
  margin: 10px;
  width: 30px;
  cursor: pointer;
}
.floating-button {
  background-color: #f9b840; /* Button color */
  color: white; /* Button text color */
  border: none;
  padding: 10px 20px;
  border-radius: 9px;
  font-size: 16px;
  cursor: pointer;
}

.floating-button:hover {
  background-color: #f9b840eb; /* Darker button color on hover */
}
.lat-call-icon{
  color: #04e604;
    transform: rotate(90deg);
    margin-right: 6px;
}
.accept-call-phone{
  transform: rotate(90deg);
    font-size: 21px;
}
.navbar .dropdown-menu{
  right: 0;
    left: auto;
    min-width: 14rem;
}
.navbar .dropdown-toggle::after {
  display: none !important;
}

.custom-close-button:hover {
  background-color: darkred; /* Change the background color of the close button on hover */
}
.btn-close{
  color: #fff ! IMPORTANT;
    vertical-align: -6px;
    /* width: 15px; */
    /* height: 14px; */
    display: inline-block;
    /* font-size: 26px; */
    font-weight: 600;
}
.dropdown-menu {
  background-color: #2f3349;
    color: #fff !important;
    font-size: 16px;
    box-shadow: 0 0.25rem 1rem rgba(15, 20, 34, 0.55);
    padding: 10px;
}
.dropdown-menu > a  {
    color: #fff ;
    font-size: 17px;
    padding:8px
  
}
select {
  font-family: 'FontAwesome', 'sans-serif';
}
.containerd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
.del-note{
  float: left;
}
.note-coll{
  border-radius: 4px !important;
    color: white !important;
    border: 2px solid #5e5e5eb5  !important;
    padding-top: 8px !important;
    margin-top: 12px !important;

}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255 255 255 / 0%);
  z-index: 9999;}

.loading-gif {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
}
.choose-file-label,
  .choose-list-label {
    text-align: left;
    margin-bottom: 5px;
  }
tfoot {
  display: table-header-group;
}
tfoot input:focus {
  outline: none  !important;
  border:  none !important; 
  box-shadow: none  !important;
  border-bottom: dashed !important; 
  color: #f9b840 !important;
  
}
.modalwebhook{
  width: 120% !important;
}
.dt-paging 
 {
  justify-content: center;
  text-align: center;
  color: white !important;
}
.dt-paging-button:hover {
  border-color: #ffcd00 !important;
    border-radius: 7px;
    background-color: #ffcd00 !important;
    color: white !important;
    cursor: pointer !important;
}
.first {
 
    color: white !important;
}
.previous:hover {

    color: white !important;
    background-color: #ffcd00 !important;
}
.previous {

    color: white !important;
}
.next:hover {
  background-color: #ffcd00 !important;
  color: white !important;
}
.next {

    color: white !important;
}
.current {
  border-color: #ffcd00 !important;
    border-radius: 7px !important;
    background-color: #ffcd00 !important;
    color: white !important;
}
 .dt-layout-cell .dt-end {
  display: none !important;
}
.dt-input{
  color: white !important;
    padding: 4px;
    margin-right: 20px;
    background-color: #494949 !important;
    font-size: 16px;
    outline: none !important;
    cursor: pointer;
}
.dt-info{
  display: none !important;
}
/* .dt-start {
  display: none !important;
} */
.crm_tabble > .dt-end > .dt-search {
  display: none !important;
}
/* .dt-column-order {
  display: none !important;
 }  */
.crm_tbody:hover{
  background: transparent !important;
}
.crm_tbody{
  border-bottom-color: #313131 !important;
    border-top-color: #313131 ! IMPORTANT;
}
.crm_bd{
  background-color: #313131 !important;
}
#example > tbody > tr >td{
  color: #f9b840 !important;
  border: none !important;
  background: transparent  !important;
}
.fa::before {
  content: "\f2bb";
}

.fa-home-icon{
  margin-right: 12px!important;

  color: #6cff6cd1 !important;
}
.fa-fire-icon{
  margin-right: 12px!important;
    color: #f6775b !important;
}

.fa-warm-icon{
  margin-right: 12px;
    color: #ffa43a !important;
}

.nav-link-switch {
  background-color: transparent;
  /* color: black; */
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 6px;
    margin-right: 10px;
    color: white;
    border-color: none !important;
    border: none  !important;

}

.nav-link-switch:hover {
  background-color: #f4d03f; /* Light golden for hover */
  color: black;
}

.nav-link-switch.active-tab {
  background-color: gold; /* Active tab golden color */
  color: white; /* Optional: Change text color for active tab */
}

.fa-history-icon{
  margin-right: 12px;
    color: #a600e0 !important;
}
.unmark-chat{
  font-size: 18px;
    color: #ff0000;
}
.mark-chat{
  font-size: 18px;
    color: #ffd400;
}
.marking-btn{
  font-size: 13px;
  border-radius: 17px;
}
.fa-dnc-icon{
  margin-right: 12px;
    color: red !important;
}
.fa-chartline-icon{
  margin-right: 12px;
    color: rgba(110, 76, 236, 0.973) !important;
}

.fa-dollar-icon{
  margin-right: 12px;
    color: rgba(0, 255, 0, 0.877) !important;
}
.fa-appointment-icon{
  margin-right: 12px;
    color: rgba(0, 247, 255, 0.808) !important;
}
.fa-fabull-icon{
  margin-right: 12px;
    color: rgba(0, 255, 115, 0.808) !important;
}
.fa-thumb-icon{
  margin-right: 12px;
    color:  #bbbbbb !important;
}
/* select option {
  background-color: #f0f0f0; 
  color: #000; 
  font-size:19px;
} */
tfoot select {
  background: transparent !important;
    width: 100%;
    outline: none  !important;
    padding: 3px;
    box-shadow: none  !important;
    border-radius: 0px  !important;
    box-sizing: border-box;
    color: #f9b840 !important;
  border-bottom: dashed !important;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  outline: none !important;
  text-align: center;
  border-color: #f9b840 !important;

}
  tfoot input {
    background: transparent !important;
      width: 100%;
      outline: none  !important;
      padding: 3px;
      box-shadow: none  !important;
      border-radius: 0px  !important;
      box-sizing: border-box;
      color: #f9b840 !important;
/*      border: none !important;*/
    border-bottom: dashed !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    outline: none !important;
    text-align: center;
    border-color: #f9b840 !important;

  }
   .table-container {
    display: flex;
    flex-direction: column;
  }
  
  /* Reorder tfoot */
  .table-container table tfoot {
    order: 1;
  }
.crm_thead{
  background-color: #c3c3c3;
      color: #fff;
      border-color:#c3c3c3 !important;
}
.crm_thead >  thead  >tr > th:before {
  content: "";
    position: absolute;
    background: #ffffff;
    width: 1px;
    right: 0;
    top: 8px;
    bottom: 8px;
}
.dropdown-menu > li > a:hover  {
  background: transparent;
  

}
.dropdown-menu > a:hover  {
  color: #ffa600 !important;
  background: transparent;
  font-size: 17px;
  padding:8px

}
#balance_show{
  color: #fff;
    font-size: 13px;
    margin-right: 12px;
     font-style: italic; 
}
.col-form-label{
  margin-left: 12px;
  white-space: nowrap;
}
textarea {
  background-color: #494949 !important;
  border: none !important;
  box-shadow: 0 0 4px #0000004d !important;
  border-color: #4a4a4a !important;
  border-radius: 20px !important;
  color: #fff !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
#dash-num-color{
  color:#fff;
  font-size: 18px;
}
.custom-card-element {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  /* Add any additional styles as needed */
}

input{
  background-color: #494949 !important;
    border: none !important;
    box-shadow: 0 0 4px #0000004d !important;
    border-color: #4a4a4a !important;
    border-radius: 20px !important;
    color:#fff !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

hr{
  color: #cfcfcf !important;
}
/* header userinfo css */
.head-dropdown{
  color: #fff !important;
}
.head-dropdown:hover{
  color: #fff !important;
  background: transparent !important;
}
.text-muted{
  color:#7983bb !important
}

/* #ticket css */
.ticket-view{
  font-size: 18px !important;
    padding: 9px;
    background: white;
    border: 2px solid #494949;
    margin: 2px 357px 39px 21px;
}
/* creat_template */
.labelCheckList{
  margin: 0 0 6px;
}
input.custom-cls-bg {
  width: 71px;
  text-align: center;
  margin: 0 0 4px;

  padding: 2px 0 3px;
  background: #C3C3C3 !important;
  border-radius: 20px;
  border: none;
  height: 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 6px;
  color: #fff;
  outline: none;
  
}



.card{
  border: 0px;
  background: rgb(42,45,54)  !important;
}
.bg-dark{
  background: rgb(42,45,54)  !important;
}

