.active_chanel{
    background: #f9b840;
    box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.48);
    color: #fff !important;
}

.add-num-btn{
    background: #ffa500;
    border-radius: 50px;
    border: none;
    margin-right: 12px;
}
.add-num-btn > img{
    width: 12px;
    height: 20px;
}
.file-reply-icon{
  font-size: 22px;
  margin-right: 5px;
  margin-top: 8px;
    color:#747272 ;
    cursor: pointer;
}
.auto-replies {
  list-style: none;
  padding: 0;
}

.auto-replies li {
  margin-bottom: 14px; /* Increased margin-bottom for spacing */
  padding: 8px; /* Added padding */
  border: 1px solid #ccc; /* Added border */
  border-radius: 5px; /* Rounded corners for aesthetics */
  cursor: pointer;
}
.new-num-input:focus{
  outline:none !important;
  border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
}
#contact-info-input{
  width: 112px !important;
    padding: 5px 5px 6px !important;
    font-size: 12px !important;
    outline: none !important;
    border: 0px !important;
    background-color: transparent !important;
    border-bottom: 2px solid white !important;
    border-radius: 0px !important;
    color: white !important;
    margin-left: 5px;
    float: right;
    height: 26px;
}
.new-num-input{
  color: white !important;
  margin-left: 6px;
  box-shadow: none  !important;
    background-color: transparent !important;
    border-bottom: 2px solid #fff !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important;
    outline: none !important;
    display: block !important;
    width: 100% !important;
    padding: 0.422rem 0.875rem !important;
    font-size: 0.9375rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
}
.auto-replies li:hover {
  background-color: rgba(128, 128, 128, 0.647);
}
.num-img{
    width: 40px;
    height: 39px;
    margin-right: -17px;
}
.animated-column {
    overflow: hidden;
    transition: transform 0.5s; /* Only transition transform property */
  }
  .custom-toggle {
    font-size: 11px;
    cursor: pointer;
    width: 88px;
    border-radius: 50px;
    outline:'none  !important';
    background: #494949;
    border-color: #49494949;
    color:#fff !important;
  }
  .custom-toggle:hover {
    
    background: #494949;
    border-color: #49494949;
    color:#fff !important;

  }
  .custom-toggle:focus {
    box-shadow: none; /* Optional: Remove the focus outline */
  }
  /* Add this style to translate to 50% when hidden */
  .animated-column.hidden {
    transform: translateX(50%);
  }
  
.dt-li{
    list-style: none;
    font-size: 14px;
    background: #1e1c1c5c;
    color: #fff;
    padding:6px;
    box-shadow: 0px 2px 6px 0px rgb(4 4 4 / 29%);
    justify-content: left !important;
}

/* .st-tag{
    width: '50%' !important;
    font-size: '11px'  !important;
    padding: '4px'  !important;
    margin-right:'12px'  !important;
    border-radius:'6px !important';
} */
.nme-cn{
    margin-left: 14px;
    font-size: 17px;
    color: #dbdbdb !important;
    font-weight: 500;
}

.div-col2{
  overflow-y: scroll;
    /* height: 100vh !important; */
    /* height: 397px !important; */
}
.chat-section{
    background: #26272d;
    border: 2px solid #d1d1d161;
    
    border-radius: 10px;
    font-size: 11px;
    border: 2px solid #d1d1d161;
    border-radius: 10px;
    padding: 4px 14px 4px 16px;
    color: #ffffffad;
    margin-right: 12px;
}
.add-num-li{
  list-style: none !important;
    font-size: 14px;
    background: #1e1c1c5c;
    color: #fff;
    box-shadow: 0px 2px 6px 0px rgb(4 4 4 / 29%);
    justify-content: left !important;
}
.inpt_search{
    background: transparent !important;
    border-radius: 0px !important;
      border-top: none !important;
      border-left: none !important;
      box-shadow: none !important;
      /* border-bottom: 2px dashed #fff !important; */
  }
.inbox_number > li{
    padding: 5px 10px;
    /* font-size: 14px; */
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between
}
.inbox_number > li.active_chanel:hover {
  background: #f9b840;
    box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.48);
    color: #fff !important;}

.inbox_number > li:hover{
  background-color: #494949d4;
  box-shadow: 0px 1px 3px 3px rgba(255, 255, 255, 0.038);

}
.chatRoomName{
    margin: 0px;
    font-weight: 700;
}
.chat-name{
    margin: 0px;
    font-weight: 500;

}
.chat_no{
   
    color: #fff;
    font-weight: 500;
}

.lst_msg{
   
    color: #fff;
    font-weight: 300;
}

/* chat sec */
.message-container {
    display: flex;
    align-items: flex-start; /* Align items at the start (top) */
    list-style-type: none; /* Remove default list styling */
}

.image-container {
    margin-right: 11px;
    margin-top: 18px;
    width: 50px;
}

.content-container {
    flex-grow: 1; /* Allow the content container to grow */
}

.message-data {
    margin-bottom: 5px; /* Add space between date and message */
}

.my-message {
    background-color: #DCF8C6; /* Example background color */
    padding: 10px;
    border-radius: 10px; /* Rounded corners */
    font-size: 12px;
    word-break: break-word;
}

.chat {
  min-width: -webkit-fill-available;
    float: left;
    /* background: #F2F5F8; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #434651;
    font-size: 12px;
  }
  .chat .chat-header {
    padding: 8px;
    /* border-bottom: 2px solid white; */
    color: #fff;
  }
  .chat .chat-header img {
    float: left;
  }
  .chat .chat-header .chat-about {
    cursor: pointer;
    float: left;
    padding-left: 10px;
    margin-top: 6px;
  }
  .chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 16px;
  }
  .chat .chat-header .chat-num-messages {
    color: #92959E;
  }

  .chat .chat-header .fa-star {
    float: right;
    color: #D8DADF;
    font-size: 20px;
    margin-top: 12px;
  }
  .input-area{
	background: #e4eaee;
	padding: 6px;
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	border-top: 1px solid #cfdae1;
}
.input-area .input-wrapper{
	background: #fff;
	border: 1px solid #cfdae1;
	border-radius: 5px;
	overflow: hidden;
	float: left;
}
.input-area .input-wrapper input{
	height: 30px;
	line-height: 30px;
	border: 0;
	margin: 0;
	padding: 0 10px;
	outline: none;
	color: #5D7185;
	min-width: 271px;
}
.input-area .input-wrapper i{
	font-size: 18px;
	color: #a0b4c0;
	margin-right: 10px;
  margin-top:8px;
	cursor: pointer;
}
.msg-inputs{
    /* position: fixed; */
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 12px;
    background: #26272d;
    padding: 31px 24px 20px 24px !important;
    
 }
         
 .msg-inputs .msg-input[type=text] {
   width: 100%;
   overflow: hidden;
   height: 36px;
    padding: 20px;
    border-radius: 10px !important;
    outline: none !important;
   /* or make 'none' */
 }
 
 .msg-inputs .send-btn{
    background-color: #f9b840;
    color: #fff;
    border: 2px solid #f9b840;
    margin-left: 5px;
    font-size: 16px;
    border-radius: 10px;
    width: 16%;
    overflow: hidden;
 }
   
 .send-btn:hover {
   background-color: #ff9e3d;
   color: white;
   cursor: pointer;
 }
.input-area > input{
	background: #a0b4c0;
	border: none;
  letter-spacing:1px;
	font-weight: bold;
	color: #fff;
	border-radius: 4px;
	float:right;
	height: 36px;
	line-height: 30px;
	cursor: pointer;
	font-family: 'Open Sans', Arial, sans-serif;
   outline: none;
}
  .chat .chat-history {
    padding: 30px 30px 20px 1px;
    /* border-bottom: 2px solid white; */
    overflow-y: scroll;
    /* height: 100vh; */
    /* height: 355px; */
  }
  .chat .chat-history .message-data {
    margin-bottom: 15px;
  }
  .chat .chat-history .message-data-time {
    color: #a8aab1;
    padding-left: 6px;
  }
  .chat .chat-history .message {
    color: white;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    margin-bottom: 30px;
    word-break: break-all;
    position: relative;
  }
  .chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    /* border-bottom-color: #86BB71; */
    border-width: 10px;
    margin-left: -10px;
    
  }
  .chat .chat-history .my-message {
    word-break: break-all;
    background: #1c1c1c8c;
    width: fit-content;
    float:left
  }
.message-img-status{
    clear: right;
    /* margin-top: 150px; */
      margin-right: 20px;
      text-transform: capitalize;
  }
.message-status{
  clear: right;
  /* margin-top: 50px; */
    margin-right: 20px;
    text-transform: capitalize;
}
  .chat .chat-history .other-message {
    background: #f9b840;
    font-size: 13px;
    margin-right: 13px;
    width: fit-content;
    float:right;
    shape-outside: padding-box;
    word-break: break-word;
  }
  /* .chat .chat-history .other-message:after {
    border-bottom-color: #f9b840;
    left: 93%;
  } */
  .chat .chat-message {
    padding: 30px;
  }
  .chat .chat-message textarea {
    width: 100%;
    border: none;
    padding: 10px 20px;
    font: 14px/22px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    resize: none;
  }
  .chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
    font-size: 16px;
    color: gray;
    cursor: pointer;
  }
  .chat .chat-message button {
    float: right;
    color: #94C2ED;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: #F2F5F8;
  }
  .chat .chat-message button:hover {
    color: #75b1e8;
  }
  
  .online, .offline, .me {
    margin-right: 3px;
    font-size: 10px;
  }
  
  /* .online {
    color: #86BB71;
  } */
  
  .offline {
    color: #E38968;
  }
  
  .me {
    color: #94C2ED;
  }