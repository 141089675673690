body{
  background-color: #fff;
}

.payment-btn{
  width: 100%;
    font-size: 17px !important;
    font-weight: 600 !important;
}
.authentication-wrapper {
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
  }
  .authentication-wrapper .authentication-inner {
    width: 100%;
  }
  .authentication-wrapper.authentication-basic {
    align-items: center;
    justify-content: center;
  }
  .authentication-wrapper.authentication-basic .card-body {
    padding: 2rem;
  }
  .authentication-wrapper.authentication-cover {
    align-items: flex-start;
  }
  .authentication-wrapper.authentication-cover .authentication-inner {
    height: 100%;
    margin: auto 0;
  }
  .authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg {
    width: 100%;
    margin: 2rem 0 2rem 2rem;
    height: calc(100vh - 4rem);
    border-radius: 1.125rem;
    position: relative;
  }
  .authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg .auth-illustration {
    max-height: 65%;
    z-index: 1;
  }
  .authentication-wrapper.authentication-cover .authentication-inner .platform-bg {
    position: absolute;
    width: 100%;
    bottom: 0%;
    left: 0%;
    height: 35%;
  }
  .authentication-wrapper.authentication-cover .authentication-inner .auth-multisteps-bg-height {
    height: 100vh;
  }
  .authentication-wrapper.authentication-cover .authentication-inner .auth-multisteps-bg-height > img:first-child {
    z-index: 1;
  }
  .authentication-wrapper.authentication-basic .authentication-inner {
    max-width: 400px;
    position: relative;
  }
  
  #email-pass-input:focus{
    outline:none !important;
    border-color: #f9b840  !important;
    border: 2px solid #f9b840 !important;
    /* background-color: #f9b840  !important; */
  }
#plan-card{
  background: white !important;
  color: black !important;
  border-color: #f9b840 !important;
  border: 2px solid #f9b840;
  border-radius: 12px;
}
  .ing-reg-btn{
    padding: 0.422rem 0.875rem !important;
      font-size: 0.9375rem !important;
      font-weight: 400 !important;
      line-height: 1.5 !important;
      border-radius: 0.375rem !important;
      outline: none !important ;
      text-transform: none !important;
      box-shadow: none !important;
  }
.google-icon{
      width: 20px;
    height: 20px;
    border-radius: 4px; 
}
  .google-btn{
    width: 100%;
    border-color: #1a6bd9;
    border: 1px;
    background-color: #1a6bd9;
    color: white;
    font-weight: 500 !important;

  }
  #email-pass-input{
    color: black;
    outline:none !important;
    display: block !important;
      width: 100% !important;
      padding: 0.422rem 0.875rem !important;
      font-size: 0.9375rem !important;
      font-weight: 400 !important;
      line-height: 1.5 !important;
      color: black !important;
      background-color: #fff !important;
      /* background-color: #2f3349 !important; */
      background-clip: padding-box !important;
      border: 1px solid #4349681f !important;
      appearance: none !important;
      border-radius: 0.375rem !important;
      /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important; */
  
  }

.baln-row{
  border: 2px solid #80808017;
    margin: 12px;
    font-size: 18px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

}
.list-unstyled  > li > div >span{
  color: black !Important;
}
.pay-form-fields{
  box-shadow: none  !important;
  border: 2px solid grey !important;
}
.card-element-container{
  border: 1px solid #f3a718;
    padding: 10px;
    border-radius: 7px;
    margin:10px;
    background: white !important;
}
.sel-bsn{
  width: 100%;
    border-radius: 7px;
    font-weight: 500;
    box-shadow: none !important;
}
  #color-loginform{
    color: #494949 !important;
  }
  #login-card{
    background: #fff !important;
    border: none !important;
    /* background: rgb(255 255 255 / 0%) !important; */
  }
  
  .authentication-wrapper .auth-input-wrapper .auth-input {
    max-width: 50px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: 150%;
    outline: none !important;
  }
  