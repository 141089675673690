.sidebar {
  height: 100%;
  width: 250px; /* Set default width */
  position: fixed; /* Change position to absolute */
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #3a3f39 !important;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.nav-icon > img {
  display: inline-block;
  vertical-align: middle; /* This aligns the images vertically in the middle */
}

#main {
  transition: margin-left .5s;
  padding: 16px;
  margin-left: 50px; /* Adjust margin-left to match the default width of the sidebar */
}



/* Adjust the z-index to ensure the sidebar stays on top of the content */
.sidebar {
  z-index: 1000;
}


/* .app-brand{
  display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    line-height: 1;
    min-height: 1px;
    align-items: center;
    
}
.app-brand-logo.demo {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  width: 34px;
  height: 24px;
} */
.sidebar a {
  padding-left: 15px;
  padding: 7px 20px 8px 19px;
  width: 80%;
    margin-left: 11px;
    border-radius: 7px;
  /* padding: 8px 8px 8px 16px; */
  text-decoration: none;
  font-size: 18px;
  color: #b6bee3;
  display: block;
  transition: 0.5s;
 
}
.nav-icon {
  position: relative;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #111;
  min-width: 160px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #818181;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: 0.5s;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #444;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
.sidebar-header {
  display: flex;
  align-items: center;
}

.sidebar-header img {
  margin-right: 10px; /* Adjust margin as needed */
}

.sidebar-header button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.submenu {
  display: none;
  list-style-type: none;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
 transition: 0.5s;
}

.submenu.show {
  display: block;
  opacity: 1;
  max-height: 200px; /* Adjust according to your submenu height */
}

.sidebar a.active {
  background: #f9b840;
    box-shadow: 0px 2px 6px 0px rgba(255, 255, 255, 0.48);
    color: #fff !important;
    
}
.sidebar a span{
  color: #b6bee3 !important;
}
/* .sidebar a:hover  {
  background: #ffffff1d;
    color: #fff !important;
} */

.sidebar .closebtn {
  color:#b6bee3;
  position: absolute;
  top: 0;
  background: transparent;
  background-color: transparent;
  border-style: none;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
  position: fixed;
  top: 10px;
}

.openbtn:hover {
  background-color: #444;
}
.sec-logo.hidden {
  display: none;
}

#main {
  transition: margin-left .5s;
  padding: 16px;
  margin-left: 70px  !important;
}

.nav-icon {
  display: none;
}

.nav-icon.collapsed {
  display: inline-block;
}

.nav-text {
  opacity: none; /* Hide the nav text by default */
}
.loginside{
  display: none;
}
.sidebar:hover .nav-text  {
  opacity: 1; /* Hide nav text when sidebar is hovered */
}
.sidebar.collapsed {
  width: 81px;
}

.sidebar.collapsed .nav-text .sec-logo{
  display: none !important;
}

.sidebar.collapsed:hover .nav-text  .loginside{
  display: inline-block !important;
  opacity: 1; /* Display nav text when sidebar is collapsed and hovered */
}